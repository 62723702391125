//   在js中引入axios
import axios from "axios";
import router from "@/router";
// import {getUrl} from '../../public/serverConfig';
// create an axios instance   创建axios实例
const apiUrl = window.baseUrl;
const service = axios.create({
  baseURL: apiUrl, // api 的 base_url
  timeout: 60000, // request timeout  设置请求超时时间
  withCredentials: true // 是否允许带cookie这些
  //     headers: {
  //     "Content-Type": "application/json;charset=utf-8",
  // }
});

// request interceptor
service.interceptors.request.use(
  config => {
    let userId = Number(localStorage.getItem("userId"));
    if (localStorage.getItem("coldChainAMHead"))
      config.headers = {
        ...config.headers,
        coldChainAMHead: localStorage.getItem("coldChainAMHead")
      };
    if (config.method === "post") {
      config.data.token = localStorage.getItem("token");
      config.data.userId = userId;
    } else if (config.method === "get") {
      // console.log(localStorage.getItem("userId"))
      config.params.userId = userId;
      config.params.token = localStorage.getItem("token");
    }
    // do something before request is sent
    // if (store.getters.token) {
    //     // ['X-Token'] is a custom headers key
    //     // please modify it according to the actual situation
    //     // config.headers['X-Token'] = getToken()
    //     // config.headers['token'] = getToken()
    // }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    // return Promise.reject(error)
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    // console.log(response.data.desc);
    if (
      response.data.errorDesc === "登陆过期请重新登录" &&
      response.data.errorCode == 116
    ) {
      localStorage.setItem("token", "");
      localStorage.setItem("userId", "");
      router.push("/login");
      return;
    } else if (response.status != 200) {
      return Promise.reject(response);
    } else {
      return response;
    }
  },
  error => {
    // if (error.response.status) {
    // switch (error.response.status) {
    //     // 401: 未登录
    //     // 未登录则跳转登录页面，并携带当前页面的路径
    //     // 在登录成功后返回当前页面，这一步需要在登录页操作。
    //     case 401:
    //         router.replace({
    //             path: '/login',
    //             query: {
    //                 redirect: router.currentRoute.fullPath
    //             }
    //         });
    //         break;
    //
    //     // 403 token过期
    //     // 登录过期对用户进行提示
    //     // 清除本地token和清空vuex中token对象
    //     // 跳转登录页面
    //     case 403:
    //         // Message({
    //         //     message: '登录过期，请重新登录',
    //         //     duration: 1000,
    //         //     forbidClick: true
    //         // });
    //         // 清除token
    //         localStorage.removeItem('token');
    //         store.commit('loginSuccess', null);
    //         // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
    //         setTimeout(() => {
    //             router.replace({
    //                 path: '/login',
    //                 query: {
    //                     redirect: router.currentRoute.fullPath
    //                 }
    //             });
    //         }, 1000);
    //         break;
    //
    //     // 404请求不存在
    //     case 404:
    //         // Message({
    //         //     message: '网络请求不存在',
    //         //     duration: 1500,
    //         //     forbidClick: true
    //         // });
    //         break;
    //     // 其他错误，直接抛出错误提示
    //     default:
    //         // Message({
    //         //     message: error.response.data.message,
    //         //     duration: 1500,
    //         //     forbidClick: true
    //         // });
    // }
    // return Promise.reject(error.response);
  }
  // }
);

export default service;
